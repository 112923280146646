import React from 'react';
import PropTypes from 'prop-types';
import { setClassNames } from 'ddc-classnames-js';
import Skeleton from 'wsm-skeleton';
import { usePrefs, useLabels, useRequestData } from 'wsm-common-data';

const VehicleListTitle = ({
	state,
	personalized,
	noVehicles,
	hideIfUnpersonalized
}) => {
	const { deviceType } = useRequestData();
	const labels = useLabels();
	const {
		personalizedVehiclesHeading,
		nonPersonalizedVehiclesHeading,
		displayStyle,
		headingAlignment,
		headingSize
	} = usePrefs();
	let label = '';
	const isDesktop = deviceType === 'DESKTOP';
	const isNavFragment = displayStyle !== 'Standard Horizontal';
	const { isLoading, fadeIn } = state;
	if (personalized) {
		label = labels.get(personalizedVehiclesHeading);
	} else {
		label = labels.get(nonPersonalizedVehiclesHeading);
	}
	const headerContainerMarginBottomDesktop = isNavFragment ? 'mb-4' : 'mb-6';
	const headerContainerClasses = [
		isDesktop ? headerContainerMarginBottomDesktop : ''
	];
	const headerClasses = [
		'p-0',
		'widget-heading',
		'mb-0',
		headingSize,
		`text-${headingAlignment}`,
		isDesktop ? '' : 'mx-5'
	];
	const headerLoadingClasses = [
		headingSize,
		fadeIn ? 'fade-in' : 'fade-out',
		isDesktop ? 'mb-5' : 'mx-7',
		isDesktop ? 'mt-4' : ''
	];
	// We use inline styles to decrease the cumulative layout shift.
	// if we use classes, the style sheet takes some time to load and
	// impacts in our CLS score
	const inlineHeaderStyles =
		hideIfUnpersonalized && noVehicles
			? {}
			: {
					marginBottom: isDesktop ? '0px' : '20px'
			  };
	const getLoadingElement = () => {
		// The loading state should be shown only if we aren't loading
		// vehicles and if the vehicle state has an empty array
		if (isLoading) {
			// The height of the skeleton loader is selected using this logic
			// 1.25 is the line height of all the display-x classes
			// we use this value for desktop and we use 2.5 for mobile
			// due in mobile we have two lines
			// There is a special case in the nav fragment for the skeleton loader width
			// in order to match lengths with the vehicle cards, the header width has been set to 97%
			let skeletonHeight = isDesktop ? 1.25 : 2.5;
			const skeletonWidth = isNavFragment ? '97%' : '100%';

			if (isNavFragment) {
				// the height of the skeleton header has been set to cater to only one line
				skeletonHeight = 0.5;
			}

			return (
				<div
					className={setClassNames(headerLoadingClasses)}
					data-testid="vehicle-list-title-skeleton"
				>
					<Skeleton
						width={skeletonWidth}
						height={`${skeletonHeight}em`}
					/>
				</div>
			);
		}
		// If we aren't loading but the vehicle state still an empty array
		// no title should be rendered
		return '';
	};
	const getTitleElement = () => {
		if (displayStyle === 'Navigation') {
			const navFragmentTitleClasses = [
				`text-${headingAlignment}`,
				'px-0'
			];
			return (
				<h4
					className={setClassNames(navFragmentTitleClasses)}
					data-testid="widget-heading"
				>
					<span>{label}</span>
				</h4>
			);
		} else {
			return (
				<h2
					className={setClassNames(headerClasses)}
					data-testid="widget-heading"
				>
					<span>{label}</span>
				</h2>
			);
		}
	};
	return (
		<div
			className={setClassNames(
				hideIfUnpersonalized && noVehicles ? '' : headerContainerClasses
			)}
			style={inlineHeaderStyles}
		>
			{noVehicles ? getLoadingElement() : getTitleElement()}
		</div>
	);
};

VehicleListTitle.propTypes = {
	personalized: PropTypes.bool.isRequired,
	noVehicles: PropTypes.bool.isRequired,
	hideIfUnpersonalized: PropTypes.bool.isRequired,
	state: PropTypes.shape({
		isLoading: PropTypes.bool.isRequired,
		fadeIn: PropTypes.bool.isRequired
	})
};

export default VehicleListTitle;
