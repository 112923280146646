import { useEffect, useState } from 'react';
import { hysteriFetch, wrappers } from 'hysterics';
import { useLocalStorage } from './useLocalStorage';
import { buildEODataEntry } from '../utils';

const NR_PREFIX = 'REC_VEHICLES';
const ENDPOINT_FETCH_ERROR = `${NR_PREFIX}/CLIENT/VEHICLES_ENDPOINT_FETCH_ERROR`;
const ENDPOINT_FETCH_SUCCESS = `${NR_PREFIX}/CLIENT/VEHICLES_ENDPOINT_FETCH_SUCCESS`;

const vdpDataService = hysteriFetch('vdpData', {
	timeout: {
		[wrappers.timeout.contextKey]: 1000
	}
});

const newrelicAction = (action, facets) => {
	if (window && window.newrelic && typeof window.newrelic === 'object') {
		window.newrelic.addPageAction(action, facets);
	}
};

export const useFetchVDPData = ({
	vehicleId,
	siteId,
	locale,
	deviceType,
	accountId
}) => {
	const body = {
		siteId,
		locale,
		deviceType,
		accountId
	};

	const requestBodyStringified = JSON.stringify(body);
	const [vdpData, setVdpData] = useState();
	const [storedEOData, setEOData] = useLocalStorage('ws-rec-vehicles-eo', {});

	useEffect(() => {
		const fetchData = async () => {
			if (vehicleId) {
				const requestUrl = `/api/widget/ws-rec-vehicles/vehicles/${vehicleId}`;
				let response;
				const startTime = Date.now();
				try {
					response = await vdpDataService(requestUrl, {
						method: 'POST',
						headers: {
							'content-type': 'application/json;charset=UTF-8'
						},
						body: requestBodyStringified
					});
					if (
						Object.prototype.hasOwnProperty.call(
							response,
							'chromeStyleId'
						) &&
						Object.prototype.hasOwnProperty.call(response, 'vin') &&
						Object.prototype.hasOwnProperty.call(response, 'type')
					) {
						setVdpData(response);
					} else {
						setVdpData(undefined);
					}
				} catch (err) {
					newrelicAction(`${ENDPOINT_FETCH_ERROR}`, {
						requestUrl,
						err
					});
					throw new Error(`${ENDPOINT_FETCH_ERROR}: ${err.message}`);
				}

				const requestDuration = Date.now() - startTime;
				newrelicAction(`${ENDPOINT_FETCH_SUCCESS}`, {
					requestUrl,
					responseStatus: 200,
					requestDuration
				});

				if (response) {
					setEOData(
						response.model,
						buildEODataEntry(response, storedEOData)
					);
				}
			}
		};
		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return [vdpData, storedEOData];
};
