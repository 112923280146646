import { SET_STORED_REQUEST_DATA } from '../actions/types';

export default function storedRequestData(state = '', action) {
	if (!action.error && action.payload !== undefined) {
		switch (action.type) {
			case SET_STORED_REQUEST_DATA:
				return action.payload;

			// no default
		}
	}

	return state;
}
