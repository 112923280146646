import React from 'react';
import PropTypes from 'prop-types';
import { useLabels, usePrefs } from 'wsm-common-data';

const ViewDetails = ({ isNavFragment, href, tabIndex, title, subtitle }) => {
	const labels = useLabels();
	const {
		showVehicleDetailsButton,
		vehicleDetailsButtonLabel,
		detailsButtonStyle
	} = usePrefs();

	const viewDetailsTitle = labels.get(vehicleDetailsButtonLabel);

	const showDetailsButton =
		showVehicleDetailsButton === 'true' && !isNavFragment;

	if (!showDetailsButton) {
		return null;
	}

	return (
		<a
			className={`btn btn-${detailsButtonStyle} btn-xs mt-auto `}
			href={href}
			tabIndex={tabIndex}
		>
			{viewDetailsTitle}
			<span className="sr-only">
				{title}
				{subtitle}
			</span>
		</a>
	);
};

ViewDetails.propTypes = {
	isNavFragment: PropTypes.bool.isRequired,
	href: PropTypes.string.isRequired,
	tabIndex: PropTypes.number.isRequired,
	title: PropTypes.string,
	subtitle: PropTypes.string
};

export default ViewDetails;
