/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-danger */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useRequestData, usePrefs } from 'wsm-common-data';
import { formatCurrency } from 'ddc-intl';
import { setClassNames } from 'ddc-classnames-js';
import { trackEvent } from 'ddc-track-event';
import { CUSTOM_PHOTOS } from '../server/namespaces';
import { injectTooltipContainer, vehicleInitTooltip } from '../utils';
import ViewDetails from './ViewDetails';

export default function Vehicle({
	info: { vin, link, totalPrice, imagePath, label, titleAttributes = [] },
	trackEventElement,
	index: vehicleIndex,
	activeSlide = vehicleIndex,
	trackingData = {},
	pageAlias
}) {
	const {
		showPriceLabel: showPriceLabelPref,
		showVin: showVinPref,
		priceLabelClasses: priceLabelClassesPref,
		pricingValueClasses: pricingValueClassesPref,
		pricingClasses: pricingClassesPref,
		vehicleTitleHeadingClasses: vehicleTitleHeadingClassesPref,
		vehicleTitleSubHeadingClasses: vehicleTitleSubHeadingClassesPref,
		displayStyle
	} = usePrefs();
	const { locale, deviceType, windowId, widgetName } = useRequestData();

	useEffect(() => {
		vehicleInitTooltip(deviceType, vehicleIndex);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	// Display flags
	const showPriceLabel = showPriceLabelPref === 'true';
	const showVin = showVinPref === 'true';
	const isDesktop = deviceType === 'DESKTOP';
	const isNavFragment = displayStyle !== 'Standard Horizontal';

	// Heading tags - use sequential heading tags for accessibility
	const TitleTag = isNavFragment ? 'h5' : 'h3';
	const photoHostname = imagePath && new URL(imagePath).hostname;
	const mediaClasses = [
		'media',
		'd-flex justify-content-center align-items-center',
		'w-100',
		isDesktop
			? 'vehicle-media-container'
			: 'vehicle-media-container-mobile',
		imagePath && photoHostname === CUSTOM_PHOTOS
			? 'bg-neutral-400'
			: 'bg-white'
	];

	const vehicleTitleHeadingClasses = [
		vehicleTitleHeadingClassesPref,
		isNavFragment ? 'mt-3' : 'mt-4',
		'mb-0',
		'text-body',
		'h5'
	];
	const vehicleTitleSubHeadingClasses = [
		vehicleTitleSubHeadingClassesPref,
		'mb-2',
		isNavFragment ? 'py-0 px-0' : 'pb-1',
		'text-body',
		'mt-0',
		isDesktop ? 'h3' : 'h2'
	];

	// Price container classes
	const pricingClasses = [
		pricingClassesPref,
		'd-flex justify-content-center flex-column',
		'mb-0',
		'text-body',
		'ddc-font-size-large'
	];

	// Price label classes
	const priceLabelClasses = [
		priceLabelClassesPref,
		isDesktop ? 'ddc-font-size-xsmall' : 'ddc-font-size-small',
		'vehicle-price-label',
		'text-muted'
	];

	// Price value classes
	const pricingValueDesktopFontSize = isNavFragment
		? 'ddc-font-size-base'
		: 'ddc-font-size-large';
	const pricingValueClasses = [
		pricingValueClassesPref,
		isDesktop ? pricingValueDesktopFontSize : 'ddc-font-size-xxlarge',
		'value',
		'mb-2',
		isNavFragment ? '' : 'pb-1'
	];

	// Vin label classes
	const vehicleVinClasses = [
		'vehicle-vin-label',
		'mb-2',
		isNavFragment ? '' : 'pb-1',
		'text-muted',
		isDesktop && !isNavFragment
			? 'ddc-font-size-xxsmall'
			: 'ddc-font-size-xsmall'
	];

	const detailsClasses = [
		'border-top',
		'vehicle-card-details-container',
		'ddc-section-height-default',
		'bg-contrast-low',
		'px-4',
		'w-100',
		'vehicle-card-info',
		'text-center',
		isDesktop ? '' : 'landscape-details pb-3',
		isNavFragment ? 'pb-2' : ''
	];

	const vehicleContainerClasses = [
		'h-100',
		'pointer',
		'd-flex',
		'flex-column',
		'text-decoration-none',
		'text-body',
		isNavFragment ? '' : 'pb-3'
	];

	const handleClick = ({ subtitle, title = '' }) => {
		const trackingElements = {
			pageAlias,
			element: trackEventElement,
			result: 'Navigated to VDP',
			dataToExcludeFromWidgetUserEvent: {
				link,
				subtitle,
				title,
				...trackingData
			}
		};
		trackEvent(widgetName, windowId, trackingElements);
		window.location.href = link;
	};

	const imgSrc =
		isDesktop && imagePath && displayStyle === 'Standard Horizontal'
			? `${imagePath}?impolicy=downsize_bkpt&w=410`
			: `${imagePath}?impolicy=downsize_bkpt&w=325`;

	let lineBreakIndex;
	let title = '';
	let subtitle = '';

	// Vehicle titles should be constructed according to IDC.
	if (titleAttributes.length !== 0) {
		lineBreakIndex = titleAttributes
			.map(({ name }) => name)
			.indexOf('lineBreak');
		lineBreakIndex =
			lineBreakIndex === -1 ? titleAttributes.length : lineBreakIndex;
		title = titleAttributes
			.slice(0, lineBreakIndex)
			.reduce(
				(acc, { itemValue }) =>
					itemValue ? `${acc} ${itemValue}` : acc,
				''
			)
			.trim();
		if (lineBreakIndex !== titleAttributes.length) {
			subtitle = titleAttributes
				.slice(lineBreakIndex)
				.reduce(
					(acc, { itemValue }) =>
						itemValue ? `${acc} ${itemValue}` : acc,
					''
				)
				.trim();
		}
	}

	return (
		<div
			className={setClassNames(vehicleContainerClasses)}
			onClick={() => handleClick({ subtitle, title })}
			data-testid="card"
		>
			<div className={setClassNames(mediaClasses)} data-testid="photo">
				{imagePath && (
					<img
						className="photo thumb"
						src={imgSrc}
						data-src={imgSrc}
						alt={`${title} ${subtitle}`}
					/>
				)}
			</div>
			<div className={setClassNames(detailsClasses)}>
				<TitleTag className="vehicle-card-titles m-0">
					<a
						className="d-flex flex-column text-body"
						href={link}
						data-vdp-href="#"
						data-click-url=""
						data-vehicle-tracer=""
						data-testid="card-link"
						tabIndex={activeSlide === vehicleIndex ? '0' : '-1'}
					>
						<span
							className={setClassNames(
								vehicleTitleHeadingClasses
							)}
							data-testid="card-heading"
						>
							{title}
						</span>
						<span
							className={setClassNames(
								vehicleTitleSubHeadingClasses
							)}
							style={{
								wordWrap: 'break-word'
							}}
							data-testid="card-subheading"
						>
							{subtitle}
						</span>
					</a>
				</TitleTag>
				{totalPrice ? (
					<div className={setClassNames(pricingClasses)}>
						{showPriceLabel && (
							<span
								className={`${setClassNames(
									priceLabelClasses
								)}`}
								dangerouslySetInnerHTML={{
									__html: isDesktop
										? injectTooltipContainer(label)
										: label
								}}
								onClick={(e) => e.preventDefault()}
								data-testid="totalPriceLabel"
							/>
						)}
						<span
							className={setClassNames(pricingValueClasses)}
							data-testid="totalPrice"
						>
							{totalPrice &&
								`${formatCurrency(totalPrice, locale)}`}
						</span>
					</div>
				) : null}
				{vin && showVin ? (
					<div
						className={setClassNames(vehicleVinClasses)}
						data-testid="vin"
					>
						{`VIN: ${vin}`}
					</div>
				) : null}
				<ViewDetails
					isNavFragment={isNavFragment}
					href={link}
					tabIndex={activeSlide === vehicleIndex ? '0' : '-1'}
					title={title}
					subtitle={subtitle}
				/>
			</div>
		</div>
	);
}

Vehicle.propTypes = {
	index: PropTypes.number.isRequired,
	info: PropTypes.shape({
		vin: PropTypes.string,
		uuid: PropTypes.string.isRequired,
		link: PropTypes.string.isRequired,
		totalPrice: PropTypes.oneOfType([
			PropTypes.string.isRequired,
			PropTypes.number.isRequired
		]),
		imagePath: PropTypes.string.isRequired,
		titleAttributes: PropTypes.shape(),
		label: PropTypes.string.isRequired
	}).isRequired,
	trackEventElement: PropTypes.string.isRequired,
	activeSlide: PropTypes.number.isRequired,
	trackingData: PropTypes.shape({
		bodyStyle: PropTypes.string,
		cityFuelEconomy: PropTypes.string,
		highwayFuelEconomy: PropTypes.string,
		make: PropTypes.string,
		model: PropTypes.string,
		odometer: PropTypes.string,
		stockNumber: PropTypes.string,
		uuid: PropTypes.string,
		vin: PropTypes.string,
		year: PropTypes.number
	}),
	pageAlias: PropTypes.string.isRequired
};
