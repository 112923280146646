/* eslint-disable no-undef */
import get from 'lodash.get';

export const getLoadingVehicleList = (n = 5) => {
	const loadingVehicleList = [];

	for (let i = 0; i < n; i++) {
		loadingVehicleList.push({
			id: i
		});
	}

	return loadingVehicleList;
};

export const getSeedVehicleChromeStyleId = () => {
	return (
		(window &&
			window.DDC &&
			window.DDC.WidgetData &&
			window.DDC.WidgetData['ws-amp-offer'] &&
			window.DDC.WidgetData['ws-amp-offer'].offerChromeStyleId) ||
		null
	);
};

export const getOfferVehicleType = () => {
	return (
		(window &&
			window.DDC &&
			window.DDC.WidgetData &&
			window.DDC.WidgetData['ws-amp-offer'] &&
			window.DDC.WidgetData['ws-amp-offer'].offerVehicleType) ||
		null
	);
};

export const buildQueryParams = (queries, urlObj = null) => {
	const queryArray = Object.entries(queries);
	if (urlObj) {
		queryArray.forEach(([key, value]) => {
			if (queries[key]) {
				urlObj.searchParams.append(key, value);
			}
		});
		return urlObj.search;
	} else {
		return queryArray.reduce((queryString, [key, value], i) => {
			let query = queryString;
			const isLastItem = i === queryArray.length - 1;
			if (!queries[key]) {
				return query;
			}
			if (!isLastItem) {
				query += `${key}=${encodeURIComponent(value)}&`;
			} else {
				query += `${key}=${encodeURIComponent(value)}`;
			}
			return query;
		}, '?');
	}
};

export const filterLocaleLabels = (vehicle, locale) => {
	const trim = get(vehicle, `trim.${locale}`, '');
	const model = get(vehicle, `model.${locale}`, '');

	return {
		...vehicle,
		trim,
		model
	};
};

export const mergeVehicleDataWithResponses = (vehicleData, responses) => {
	return vehicleData.map((vehicle) => {
		const { uuid } = vehicle;
		const vehicleResponses = responses.filter(
			({ uuid: responseUUID }) => responseUUID === uuid
		);
		return vehicleResponses.reduce((accVehicle, response) => {
			return {
				...response,
				...accVehicle
			};
		}, vehicle);
	});
};

export const hasExcludedImages = ({ images }, excludeBasedOnImages) => {
	switch (excludeBasedOnImages) {
		case 'excludeItemsWithNoImages':
			return (
				!images ||
				!images[0]?.uri ||
				images[0]?.uri.includes('unavailable_stockphoto')
			);
		case 'excludeItemsWithOnlyStockImagesAndNoImages':
			return (
				!images ||
				!images[0]?.uri ||
				images[0]?.uri.includes('://images')
			);
		default:
			return false;
	}
};

export const buildEODataEntry = (
	{ model, make, chromeStyleId, type },
	previousState
) => {
	const previousModelEntry = previousState[model];
	let previousViews = previousModelEntry?.views ?? [];
	const previousChromeStyleIdEntry = previousViews.find(
		({ chromeStyleId: chromeId }) => chromeId === chromeStyleId
	);
	if (!previousModelEntry || previousViews.length === 0) {
		// If we don't find an entry, we create a new one
		return {
			make,
			views: [
				{
					chromeStyleId,
					count: 1,
					type
				}
			]
		};
	} else {
		previousViews = previousViews.filter(
			({ chromeStyleId: chromeId }) => chromeId !== chromeStyleId
		);
		const previousChromeStyleIdEntryCount =
			previousChromeStyleIdEntry?.count ?? 0;
		// If we find an entry, we merge the values
		return {
			make,
			views: [
				...previousViews,
				{
					chromeStyleId,
					count: 1 + previousChromeStyleIdEntryCount,
					type
				}
			]
		};
	}
};

export const getChromeStyleIdWithMoreViews = (storedEOData) => {
	let entryWithMoreViews = { count: 0 };
	const flatViews = Object.entries(storedEOData).reduce(
		(accViews, [_model, currEntry]) => {
			if (currEntry && currEntry.views) {
				return [...accViews, ...currEntry.views];
			} else {
				return accViews;
			}
		},
		[]
	);

	if (flatViews.length > 0) {
		entryWithMoreViews = flatViews.reduce(
			(currEntryWithMoreViews, currEntry) => {
				if (currEntryWithMoreViews.count < currEntry.count) {
					return currEntry;
				} else {
					return currEntryWithMoreViews;
				}
			},
			{ count: 0 }
		);
	}

	return entryWithMoreViews;
};

const getTotalViewsCount = (views) => {
	return views.reduce((total, { count = 0 }) => {
		return total + count;
	}, 0);
};

export const getMakeModelWithMoreViews = (storedEOData) => {
	return Object.entries(storedEOData)
		.map(([model, { make, views }]) => {
			return {
				model,
				make,
				count: getTotalViewsCount(views ?? [])
			};
		})
		.reduce(
			(highestMakeModel, currMakeModel) => {
				if (highestMakeModel.count < currMakeModel.count) {
					return currMakeModel;
				} else {
					return highestMakeModel;
				}
			},
			{ count: 0 }
		);
};

export const sortByVINOrder = (vinList, vehicleData) => {
	const vinSortMap = vinList.reduce((acc, vin, index) => {
		return {
			[vin]: index,
			...acc
		};
	}, {});
	return vehicleData.sort(({ vin: vinA }, { vin: vinB }) => {
		if (vinSortMap[vinA] < vinSortMap[vinB]) {
			return -1;
		} else if (vinSortMap[vinA] > vinSortMap[vinB]) {
			return 1;
		}
		return 0;
	});
};

export const shouldTrackEvent = (data, state) =>
	data?.vehicles?.length > 0 &&
	!state.fadeIn &&
	!state.isLoading &&
	!state.collapse;

export const intersectionObserverHandler =
	(callback, intersectionObserverId) => (entities) => {
		const [entity] = entities;
		if (entity.intersectionRatio > 0.5) {
			callback();
			if (
				intersectionObserverId &&
				intersectionObserverId?.current?.disconnect
			) {
				intersectionObserverId.current.disconnect();
			}
		}
	};

export const reInitTooltip = (deviceType) => {
	if (
		deviceType === 'DESKTOP' &&
		window &&
		window.DDC &&
		window.DDC.modules &&
		window.DDC.modules.popover &&
		typeof window.DDC?.modules?.popover !== 'undefined'
	) {
		$(document).trigger('modulesRefresh', {
			modules: { popover: window.DDC.modules.popover }
		});
	}
	if (
		deviceType === 'MOBILE' &&
		window &&
		window.DDC &&
		window.DDC.mobile &&
		window.DDC.mobile.slidein &&
		typeof window.DDC?.mobile?.slidein !== 'undefined'
	) {
		window.DDC.mobile.slidein.init();
	}
};

export const injectTooltipContainer = (label = '') => {
	if (label.includes('data-toggle="popover"')) {
		return label.replace(
			/(data-toggle="popover")/,
			'$1 data-container="body" '
		);
	}
	return label;
};

export const vehicleInitTooltip = (deviceType, index) => {
	// re-initialize tooltip code
	// return booleans do not affect widget, it is for testing purposes
	if (index !== 0) return false;

	reInitTooltip(deviceType);

	return true;
};

export const filterOutVdpVehicles = (vehicles, vin) => {
	if (!vehicles) {
		return [];
	}

	if (!vin) {
		return vehicles;
	}

	return vehicles.filter((vehicle) => vehicle.vin !== vin);
};
