import { useEffect, useState, useRef } from 'react';
import { hysteriFetch } from 'hysterics';
import { isBrowser } from 'ws-scripts/modules/environment';
import { useRequestData, useFlags, usePrefs } from 'wsm-common-data';
import { trackEvent } from 'ddc-track-event';
import {
	getSeedVehicleChromeStyleId,
	getOfferVehicleType,
	getChromeStyleIdWithMoreViews,
	getMakeModelWithMoreViews,
	shouldTrackEvent,
	intersectionObserverHandler
} from '../utils';

const NR_PREFIX = 'REC_VEHICLES';
const ENDPOINT_FETCH_ERROR = `${NR_PREFIX}/CLIENT/VEHICLES_ENDPOINT_FETCH_ERROR`;
const ENDPOINT_FETCH_SUCCESS = `${NR_PREFIX}/CLIENT/VEHICLES_ENDPOINT_FETCH_SUCCESS`;

const vehicleDataService = hysteriFetch('vehicleData', {
	timeout: {
		timeout: 2000
	}
});

const newrelicAction = (action, facets) => {
	if (window && window.newrelic && typeof window.newrelic === 'object') {
		window.newrelic.addPageAction(action, facets);
	}
};

const getVehicleTrackingData = (vehicles) =>
	vehicles.map((vehicle) => ({
		bodyStyle: vehicle.bodyStyle,
		year: vehicle.year,
		make: vehicle.make,
		model: vehicle.model,
		stockNumber: vehicle.stockNumber,
		vin: vehicle.vin,
		odometer: vehicle.odometer,
		cityFuelEconomy: vehicle.fuelEconomy?.value?.split('/')[0],
		highwayFuelEconomy: vehicle.fuelEconomy?.value?.split('/')[1]
	}));

const getDataToExcludeFromWidgetUserEvent = (vehicles, cardsShowing) => ({
	vehicles: getVehicleTrackingData(vehicles)?.slice(0, cardsShowing)
});

const getNumberOfCardsShowing = (container) => {
	const nodeList = container?.querySelectorAll('.vehicle-list > li');
	const cards = Array.from(nodeList);
	return cards?.filter((card) => card?.offsetWidth && card?.offsetHeight)
		?.length;
};

export const useFetchVehicleData = (
	{
		accountId,
		containerRef,
		siteId,
		displayerInstanceId,
		excludeBasedOnImages,
		listingConfigId,
		requiredDisplayAttributes,
		hideIfUnpersonalized,
		vdpData,
		pageAlias,
		pageId,
		uri,
		host,
		scheme,
		storedEOData,
		vehicleId
	},
	{ vehicleState, startFadeIn, startFadeout, startCollapse }
) => {
	// Hooks
	const [response, setResponse] = useState({
		vehicleData: [],
		personalized: false,
		trackEventElement: ''
	});
	const { deviceType, locale, windowId, widgetName } = useRequestData();
	const { displayStyle, geoRange } = usePrefs();
	const isNavFragment = displayStyle !== 'Standard Horizontal';
	const flags = useFlags();
	const intersectionObserverId = useRef(null);
	// Experience optimized variables
	const { chromeStyleId: eoChromeId, type: eoType } =
		getChromeStyleIdWithMoreViews(storedEOData);
	const { make: eoMake, model: eoModel } =
		getMakeModelWithMoreViews(storedEOData);
	// Flags
	const enableVDPLink = flags['ws-vdplink-endpoint-enabled'];
	const addTimeout = flags['wsm-account-data-distributor-timeout'];
	const numResultsCVRE = flags['ws-rec-vehicles-cvre-num-results'];

	async function fetchVehicleData() {
		const requestUrl = `/api/widget/ws-rec-vehicles/vehicles-recommendations/${accountId}`;
		const pixallId =
			// eslint-disable-next-line max-len,no-nested-ternary
			window.Cookies && window.Cookies.get('abc')
				? window.Cookies.get('abc')
				: window.Cookies && window.Cookies.get('pxa_id')
				? window.Cookies.get('pxa_id')
				: null;
		const sessionStorageLocation = JSON.parse(
			window.sessionStorage.getItem('session.location')
		);
		const userPostalCode =
			window.localStorage.getItem('userLocation') ||
			sessionStorageLocation.userPostalCode;
		const offerSeedVehicleChromeStyleId = getSeedVehicleChromeStyleId();
		const seedVehicleType = getOfferVehicleType();
		const inventoryRequestBody = {
			siteId,
			locale,
			device: deviceType.toLowerCase(),
			pageAlias,
			pageId,
			windowId,
			referrer: document.referrer,
			host,
			uri,
			scheme
		};
		const requestBody = {
			pixallId,
			offerSeedVehicleChromeStyleId,
			seedVehicleType,
			siteId,
			displayLimit: displayStyle === 'Navigation' ? 8 : 6,
			enableVDPLink,
			addTimeout,
			displayerInstanceId,
			eoChromeId,
			eoModel,
			eoMake,
			eoType,
			locale,
			excludeBasedOnImages,
			listingConfigId,
			requiredDisplayAttributes,
			numResultsCVRE,
			hideIfUnpersonalized,
			inventoryRequestBody,
			geoRange,
			geoZip: userPostalCode
		};

		if (vdpData) {
			requestBody.vdpData = {
				chromeStyleId: vdpData?.chromeStyleId,
				type: vdpData?.type,
				vin: vdpData?.vin
			};
		}
		const requestBodyStringified = JSON.stringify(requestBody);

		const startTime = Date.now();

		let data;

		try {
			if (!hideIfUnpersonalized) {
				startFadeIn();
			}
			data = await vehicleDataService(requestUrl, {
				method: 'POST',
				headers: {
					'content-type': 'application/json;charset=UTF-8'
				},
				body: requestBodyStringified
			});
		} catch (e) {
			startCollapse();
			const { message: error } = e;
			newrelicAction(`${ENDPOINT_FETCH_ERROR}`, {
				requestUrl,
				error
			});
			throw new Error(`${ENDPOINT_FETCH_ERROR}: ${e.message}`);
		}

		const requestDuration = Date.now() - startTime;

		newrelicAction(`${ENDPOINT_FETCH_SUCCESS}`, {
			requestUrl,
			responseStatus: 200,
			requestDuration,
			totalVehicles: data?.vehicles?.length ?? 0
		});
		if (data?.vehicles?.length > 0) {
			startFadeout(() => {
				setResponse({
					vehicleData: data.vehicles,
					personalized: data.personalized,
					trackEventElement: data.trackEventElement
				});
			});
		} else {
			startCollapse();
		}
		return data;
	}

	useEffect(() => {
		if (vehicleId && !vdpData && !isNavFragment) {
			// no requests made if not nav fragment + no vdp data inside of a vdp
			// Skeleton placeholder component will not fadeaway
			return null;
		}
		// Only runs this in the browser
		if (isBrowser && containerRef) {
			// Makes sure that the browser is compatible with the intersection Observer
			if (typeof IntersectionObserver !== 'undefined') {
				const options = {
					threshold: 0.5
				};
				if (intersectionObserverId.current) {
					// If component did update, we want to disconnect the previous intersection observer
					intersectionObserverId.current.disconnect();
				}
				const target = containerRef.current;
				if (!hideIfUnpersonalized) {
					// By default we load recommendations only when the widget is in the viewport
					intersectionObserverId.current = new IntersectionObserver(
						intersectionObserverHandler(async () => {
							// we get vehicle data until the widget is viewed
							const data = await fetchVehicleData();
							if (shouldTrackEvent(data, vehicleState)) {
								const cardsShowing =
									getNumberOfCardsShowing(target);
								const trackingElements = {
									pageAlias,
									element: data.trackEventElement,
									action: 'viewed',
									result: 'Recommended vehicles impression',
									nonInteractive: true,
									dataToExcludeFromWidgetUserEvent:
										getDataToExcludeFromWidgetUserEvent(
											data.vehicles,
											cardsShowing
										)
								};
								trackEvent(
									widgetName,
									windowId,
									trackingElements
								);
							}
						}, intersectionObserverId),
						options
					);
					intersectionObserverId.current.observe(target);
				} else {
					// We load vehicle data just after loading the page
					fetchVehicleData().then((data) => {
						// We track the "viewed" event until half of the widget is in the viewport
						intersectionObserverId.current =
							new IntersectionObserver(
								intersectionObserverHandler(() => {
									if (shouldTrackEvent(data, vehicleState)) {
										const cardsShowing =
											getNumberOfCardsShowing(target);
										const trackingElements = {
											pageAlias,
											element: data.trackEventElement,
											action: 'viewed',
											result: 'Recommended vehicles impression',
											nonInteractive: true,
											dataToExcludeFromWidgetUserEvent:
												getDataToExcludeFromWidgetUserEvent(
													data.vehicles,
													cardsShowing,
													data.personalized
												)
										};
										trackEvent(
											widgetName,
											windowId,
											trackingElements
										);
									}
								}, intersectionObserverId),
								options
							);
						intersectionObserverId.current.observe(target);
					});
				}
			} else {
				// If the browser doesn't support the intersection observer we make
				// the request right away
				fetchVehicleData().then((data) => {
					if (shouldTrackEvent(data, vehicleState)) {
						const cardsShowing = getNumberOfCardsShowing(
							containerRef.current
						);
						const trackingElements = {
							pageAlias,
							element: data.trackEventElement,
							action: 'viewed',
							result: 'Recommended vehicles impression',
							nonInteractive: true,
							dataToExcludeFromWidgetUserEvent:
								getDataToExcludeFromWidgetUserEvent(
									data.vehicles,
									cardsShowing
								)
						};
						trackEvent(widgetName, windowId, trackingElements);
					}
				});
			}
		}
		return () => intersectionObserverId?.current?.disconnect();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [storedEOData]);

	return [response];
};
