import { useState } from 'react';

export const useLocalStorage = (localStorageKey, initialLocalStorageValue) => {
	// Pass initial state handler to useState so logic is only executed once
	const [storedState, setStoredState] = useState(() => {
		try {
			// Get from local storage by key
			const item = window.localStorage.getItem(localStorageKey);
			// Parse stored json or if none return initialValue
			return item ? JSON.parse(item) : initialLocalStorageValue;
		} catch (error) {
			// If the item hasn't the proper format to be converted in
			// an object, we need to catch the error here and return
			// the initial value instead
			return initialLocalStorageValue;
		}
	});

	// Return a wrapped version of useState's setter function that
	// Persists the new value to localStorage.
	const setStateAndLocalStorage = (newStateKey, newStateValue) => {
		try {
			if (newStateKey && newStateValue) {
				const newState = {
					...storedState,
					[newStateKey]: newStateValue
				};
				// Save state
				setStoredState(newState);
				// Save to local storage
				window.localStorage.setItem(
					localStorageKey,
					JSON.stringify(newState)
				);
			}
		} catch (error) {
			// TODO: add a NR attribute
		}
	};
	return [storedState, setStateAndLocalStorage];
};
