/* eslint-disable react-hooks/rules-of-hooks */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { usePrefs, useRequestData } from 'wsm-common-data';
import { setClassNames } from 'ddc-classnames-js';

import { useVehiclesState } from '../hooks/useVehiclesState';
import { useFetchVehicleData } from '../hooks/useFetchVehicleData';
import { useFetchVDPData } from '../hooks/useFetchVDPData';
import VehicleListWrapper from '../components/Vehicle-List-Wrapper';
import VehicleListTitle from '../components/Vehicle-List-Title';
import Slider from '../components/Slider';

import '../global-sass/main.scss';

function WidgetContainer({
	accountId,
	siteId,
	vehicleId,
	pageAlias,
	pageId,
	uri,
	host,
	scheme
}) {
	const {
		displayerInstanceId,
		excludeBasedOnImages,
		'listing.config.id': listingConfigId,
		showPriceLabel: showPriceLabelPref,
		showVin: showVinPref,
		hideIfUnpersonalized: hideIfUnpersonalizedPref,
		containerClasses: prefClasses,
		'required.display.attributes': requiredDisplayAttributes
	} = usePrefs();
	const hideIfUnpersonalized = hideIfUnpersonalizedPref === 'true';
	const { deviceType, locale } = useRequestData();
	const isDesktop = deviceType === 'DESKTOP';
	const [vehicleState, startFadeIn, startFadeout, startCollapse] =
		useVehiclesState();

	const [vdpData, storedEOData] = useFetchVDPData({
		vehicleId,
		siteId,
		locale,
		deviceType,
		accountId
	});

	const containerRef = useRef(null);
	const { isLoading, collapse } = vehicleState;
	const hookProps = {
		accountId,
		containerRef,
		siteId,
		displayerInstanceId,
		excludeBasedOnImages,
		listingConfigId,
		requiredDisplayAttributes,
		hideIfUnpersonalized,
		vdpData: vdpData || undefined,
		pageAlias,
		pageId,
		uri,
		host,
		scheme,
		storedEOData,
		vehicleId: vdpData ? vehicleId : undefined
	};
	const handlersProps = {
		vehicleState,
		startFadeIn,
		startFadeout,
		startCollapse
	};

	const [{ vehicleData, personalized, trackEventElement }] =
		useFetchVehicleData(hookProps, handlersProps);

	const containerClasses = [
		'rec-vehicles-container',
		'text-center',
		'section',
		'm-0',
		isLoading ? 'loading bg-none h-auto' : '',
		prefClasses
	];

	const showPriceLabel = showPriceLabelPref === 'true';
	const showVin = showVinPref === 'true';
	const displayPref = { showPriceLabel, showVin };

	// Inline styles help us to improve CLS
	let minHeightValue = isDesktop ? 477 : 440;
	if (showPriceLabel) {
		minHeightValue += isDesktop ? 17 : 10;
	}
	if (showVin) {
		minHeightValue += isDesktop ? 22 : 15;
	}
	const minHeight = `${hideIfUnpersonalized ? 0 : minHeightValue}px`;

	if (collapse) {
		return <div />;
	}
	return (
		<div
			className={setClassNames(containerClasses)}
			ref={containerRef}
			style={{ minHeight }}
			data-display-sets="TITLE"
			hide-base-facet-category="true"
		>
			<VehicleListTitle
				state={vehicleState}
				personalized={personalized}
				noVehicles={vehicleData.length === 0}
				hideIfUnpersonalized={hideIfUnpersonalized}
			/>
			{isDesktop ? (
				<VehicleListWrapper
					pageAlias={pageAlias}
					vehicleState={vehicleState}
					vehicleData={vehicleData}
					displayPref={displayPref}
					trackEventElement={trackEventElement}
				/>
			) : (
				<Slider
					pageAlias={pageAlias}
					vehicleState={vehicleState}
					vehicleData={vehicleData}
					trackEventElement={trackEventElement}
				/>
			)}
		</div>
	);
}

WidgetContainer.propTypes = {
	accountId: PropTypes.string.isRequired,
	siteId: PropTypes.string.isRequired,
	vehicleId: PropTypes.string.isRequired,
	pageAlias: PropTypes.string.isRequired,
	pageId: PropTypes.string.isRequired,
	uri: PropTypes.string.isRequired,
	host: PropTypes.string.isRequired,
	scheme: PropTypes.string.isRequired
};

const mapStateToProps = (state) => {
	const {
		accountId,
		siteId,
		vehicleId,
		pageAlias,
		pageId,
		uri,
		host,
		scheme
	} = state.storedRequestData;

	return {
		accountId,
		siteId,
		vehicleId,
		pageAlias,
		pageId,
		uri,
		host,
		scheme
	};
};

const mapDispatchToProps = (_dispatch) => ({});

const Widget = connect(mapStateToProps, mapDispatchToProps)(WidgetContainer);

export default Widget;
