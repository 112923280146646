import React from 'react';
import Skeleton from 'wsm-skeleton';
import { setClassNames } from 'ddc-classnames-js';
import PropTypes from 'prop-types';

const VehicleLoader = ({ vehicleState: { fadeIn }, displayStyle }) => {
	const vehicleLoaderContainerClasses = [
		'vehicle',
		'clearfix',
		'pointer',
		'p-0',
		fadeIn ? 'fade-in' : 'fade-out'
	];

	const isMegaMenu = displayStyle === 'Navigation';

	return (
		<div className={setClassNames(vehicleLoaderContainerClasses)}>
			<div
				className="vehicle-card-media-container m-0 w-100 h-100 absolute-top"
				data-testid="shimmer-wrapper"
			>
				<Skeleton skeletonClasses="h-100" width="100%" />
			</div>
			<div className="vehicle-card-details-container ddc-section-height-default p-2 absolute-bottom h-50 w-100 bg-neutral-0">
				<div className="vehicle-card-info-container position-relative h-100 d-flex flex-column justify-content-center">
					{isMegaMenu ? (
						<div
							className="vehicle-card-title-container"
							style={{ height: '2rem' }}
							data-testid="shimmer-wrapper"
						>
							<Skeleton
								skeletonClasses="m-auto"
								height="80%"
								width="80%"
							/>
						</div>
					) : (
						<h3
							className="vehicle-card-title-container"
							style={{ height: '2rem' }}
							data-testid="shimmer-wrapper"
						>
							<Skeleton
								skeletonClasses="m-auto"
								height="100%"
								width="80%"
							/>
						</h3>
					)}
					<ul className="vehicle-card-price-list list-unstyled h-100">
						<li
							className="vehicle-card-price-container"
							style={{ height: '1.2rem' }}
							data-testid="shimmer-wrapper"
						>
							<Skeleton
								skeletonClasses="m-auto"
								height={isMegaMenu ? '80%' : '100%'}
								width="40%"
							/>
						</li>
						<li
							className={`vehicle-card-price-container ${
								isMegaMenu ? 'mt-1' : 'mt-3'
							}`}
							style={{ height: '1.8rem' }}
							data-testid="shimmer-wrapper"
						>
							<Skeleton
								skeletonClasses="m-auto"
								height={isMegaMenu ? '50%' : '100%'}
								width="50%"
							/>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
};

VehicleLoader.propTypes = {
	vehicleState: PropTypes.shape({
		fadeIn: PropTypes.bool.isRequired
	}),
	displayStyle: PropTypes.string.isRequired
};

export default VehicleLoader;
