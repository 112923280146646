import { useState } from 'react';

const initialState = {
	isLoading: false,
	fadeIn: false,
	collapse: false
};

export const useVehiclesState = () => {
	const [vehiclesState, setVehiclesState] = useState(initialState);
	const startFadeIn = () => {
		// If we set fade in as true, means that we started loading the vehicles
		setVehiclesState((currState) => ({
			...currState,
			isLoading: true,
			fadeIn: true
		}));
	};
	const startFadeOut = (callback) => {
		setVehiclesState((currState) => ({
			...currState,
			fadeIn: false
		}));
		// we need to wait to let the fade out animation to
		// be completed
		setTimeout(() => {
			callback();
			setVehiclesState((currState) => ({
				...currState,
				isLoading: false
			}));
		}, 300);
	};
	const startCollapse = () => {
		// If we set collapse as true, means that something
		// went wrong and we need to collapse the widget,
		// but we need to wait a while to do it
		setTimeout(() => {
			setVehiclesState((currState) => ({
				...currState,
				fadeIn: false,
				collapse: true,
				isLoading: false
			}));
		}, 1000);
	};
	return [vehiclesState, startFadeIn, startFadeOut, startCollapse];
};
