import PropTypes from 'prop-types';

const getDotIndexes = (
	slideCount,
	slidesToScroll,
	slidesToShow,
	cellAlign,
	scrollMode
) => {
	const dotIndexes = [];
	let lastDotIndex = slideCount - slidesToShow;

	switch (cellAlign) {
		case 'center':
		case 'right':
			lastDotIndex += slidesToShow - 1;
			break;
		default: // do nothing
	}

	if (lastDotIndex < 0) {
		return [0];
	}

	for (let i = 0; i < lastDotIndex; i += slidesToScroll) {
		dotIndexes.push(i);
	}

	if (cellAlign === 'left' && scrollMode === 'page') {
		lastDotIndex = Math.floor(
			slideCount - (slideCount % slidesToShow || slidesToShow)
		);
	}

	dotIndexes.push(lastDotIndex);
	return dotIndexes;
};

const PagingDots = ({
	slideCount,
	slidesToScroll,
	slidesToShow,
	cellAlign,
	scrollMode,
	defaultControlsConfig,
	currentSlide,
	goToSlide
}) => {
	const getListStyles = () => ({
		position: 'relative',
		top: -10,
		display: 'flex',
		margin: 0,
		padding: 0,
		listStyleType: 'none'
	});

	const getButtonStyles = (active) => ({
		cursor: 'pointer',
		opacity: active ? 1 : 0.5,
		background: 'transparent',
		border: 'none',
		fill: 'black'
	});

	const indexes = getDotIndexes(
		slideCount,
		slidesToScroll,
		slidesToShow,
		cellAlign,
		scrollMode
	);

	const {
		pagingDotsContainerClassName,
		pagingDotsClassName,
		pagingDotsStyle = {}
	} = defaultControlsConfig;

	return (
		<ul className={pagingDotsContainerClassName} style={getListStyles()}>
			{indexes.map((index) => {
				const isActive = currentSlide === index;

				return (
					<li
						key={index}
						className={
							isActive ? 'paging-item active' : 'paging-item'
						}
						data-testid="paging-dot"
					>
						<button
							className={pagingDotsClassName}
							type="button"
							style={{
								...getButtonStyles(isActive),
								...pagingDotsStyle
							}}
							onClick={goToSlide.bind(null, index)}
							aria-label={`View Slide ${index + 1}`}
						>
							<svg className="paging-dot" width="6" height="6">
								<circle cx="3" cy="3" r="3" />
							</svg>
						</button>
					</li>
				);
			})}
		</ul>
	);
};

export default PagingDots;

PagingDots.propTypes = {
	slideCount: PropTypes.number,
	slidesToScroll: PropTypes.number,
	slidesToShow: PropTypes.number,
	cellAlign: PropTypes.string,
	scrollMode: PropTypes.string,
	defaultControlsConfig: PropTypes.shape(),
	currentSlide: PropTypes.number,
	goToSlide: PropTypes.func
};
