import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { usePrefs } from 'wsm-common-data';
import { setClassNames } from 'ddc-classnames-js';
import DeferredComponent from '../ws-scripts/components/DeferredComponent';

import VehicleLoader from './Vehicle-Loader';
import Vehicle from './Vehicle';
import PagingDots from './PagingDots';

const Carousel = React.lazy(() => import('../../node_modules/nuka-carousel'));

export default function Slider({
	vehicleData,
	vehicleState,
	trackEventElement,
	pageAlias
}) {
	const [activeSlide, setActiveSlide] = useState(0);
	const vehiclesWrapperRef = useRef(null);
	const carouselRef = useRef(null);
	const { listClassesPref, listItemClasses, displayStyle } = usePrefs();
	const { isLoading } = vehicleState;
	const isSingleVehicle = vehicleData.length === 1;

	const afterSlide = (index) => {
		setActiveSlide(index);
	};

	const carouselProps = {
		animation: 'zoom',
		cellAlign: 'center',
		cellSpacing: 20,
		className: 'pb-8',
		defaultControlsConfig: {
			pagingDotsClassName: 'px-1'
		},
		heightMode: 'current',
		initialSlideHeight: 315,
		renderCenterLeftControls: null,
		renderCenterRightControls: null,
		renderBottomCenterControls: isSingleVehicle ? null : PagingDots,
		slideOffset: isSingleVehicle ? 0 : 50,
		ref: carouselRef,
		zoomScale: 0.9,
		enableKeyboardControls: true,
		afterSlide
	};

	useEffect(() => {
		const currentVehiclesWrapperRef = vehiclesWrapperRef.current;
		if (
			currentVehiclesWrapperRef &&
			typeof ResizeObserver !== 'undefined'
		) {
			const resizeObserver = new ResizeObserver(() => {
				carouselRef.current.setDimensions();
			});
			resizeObserver.observe(currentVehiclesWrapperRef);
			return () => resizeObserver.unobserve(currentVehiclesWrapperRef);
		}
		return () => {};
	}, [vehiclesWrapperRef]);

	const commonVehicleClasses = [
		listItemClasses !== 'BLANK' ? listItemClasses : '',
		'vehicle-card',
		'overflow-hidden',
		'position-relative'
	];
	if (isLoading) {
		// returns 1 skeleton card
		const skeletonClasses = ['flex-grow-1 mx-7', ...commonVehicleClasses];
		const listClasses = [
			listClassesPref !== 'BLANK' ? listClassesPref : '',
			'list-unstyled',
			'vehicle-list',
			'd-flex',
			'flex-wrap',
			'overflow-hidden',
			'pb-8',
			'mb-0'
		];
		return (
			// Inline styles help us to improve the CLS score
			<ul
				className={setClassNames(listClasses)}
				style={{ minHeight: '316px' }}
			>
				<li
					className={setClassNames(skeletonClasses)}
					key="loader_0"
					data-testid="card-li-loader"
				>
					<VehicleLoader
						vehicleState={vehicleState}
						displayStyle={displayStyle}
					/>
				</li>
			</ul>
		);
	} else {
		// returns the mobile carousel
		return (
			<DeferredComponent>
				<Carousel {...carouselProps}>
					{vehicleData.map((singleVehicleData, index) => {
						const { uuid } = singleVehicleData;
						const vehicleCardClasses = [
							'mx-4',
							...commonVehicleClasses
						];
						return (
							<div
								className={setClassNames(vehicleCardClasses)}
								style={{ minHeight: '316px' }}
								key={uuid}
								ref={index === 0 ? vehiclesWrapperRef : null}
							>
								<Vehicle
									info={singleVehicleData}
									key={`vehicle_${uuid}`}
									index={index}
									trackEventElement={trackEventElement}
									activeSlide={activeSlide}
									pageAlias={pageAlias}
								/>
							</div>
						);
					})}
				</Carousel>
			</DeferredComponent>
		);
	}
}

Slider.propTypes = {
	vehicleData: PropTypes.arrayOf(
		PropTypes.shape({
			uuid: PropTypes.string.isRequired,
			imagePath: PropTypes.string.isRequired,
			link: PropTypes.string,
			totalPrice: PropTypes.oneOfType([
				PropTypes.string.isRequired,
				PropTypes.number.isRequired
			])
		})
	).isRequired,
	vehicleState: PropTypes.shape({
		isLoading: PropTypes.bool.isRequired
	}).isRequired,
	trackEventElement: PropTypes.string.isRequired,
	pageAlias: PropTypes.string.isRequired
};
