// logging and new relic labels
export const NR_PREFIX = 'REC_VEHICLES';
export const CVRE = 'CVRE';
export const WIS = 'WIS';
export const IDC = 'IDC';
export const VEHICLES = 'VEHICLES';
export const GET_VDP_DATA = 'GET_VDP_DATA';
export const VEHICLE_TITLE = 'VEHICLE_TITLE';
export const GET_INVENTORY = 'GET_INVENTORY';
export const FETCH_SUCCESS = 'FETCH_SUCCESS';
export const FETCH_ERROR = 'FETCH_ERROR';
export const NOT_FOUND = 'NOT_FOUND';
export const FILTERED_OUT = 'FILTERED_OUT';
export const RECOMMENDATIONS = 'RECOMMENDATIONS';
export const PERSONALIZED = 'PERSONALIZED';
export const FEATURED = 'FEATURED';
export const NEW_INVENTORY = 'NEW_INVENTORY';
export const VEHICLES_WITH_NO_IMAGES = 'VEHICLES_WITH_NO_IMAGES';
export const EMPTY = 'EMPTY';
export const ADD = 'ADD';

export const CVRE_FETCH_ERROR = `${CVRE}/${FETCH_ERROR}`;
export const CVRE_RECOMMENDATIONS = `${CVRE}/${RECOMMENDATIONS}`;
export const CVRE_RECOMMENDATIONS_NOT_FOUND = `${CVRE}/${RECOMMENDATIONS}/${NOT_FOUND}`;
export const CVRE_RECOMMENDATIONS_FILTERED_OUT = `${CVRE}/${RECOMMENDATIONS}/${FILTERED_OUT}`;
export const GET_VDP_DATA_FETCH_ERROR = `${WIS}/${GET_VDP_DATA}/${FETCH_ERROR}`;
export const GET_INVENTORY_PERSONALIZED = `${WIS}/${GET_INVENTORY}/${PERSONALIZED}`;
export const GET_INVENTORY_FEATURED = `${WIS}/${GET_INVENTORY}/${FEATURED}`;
export const GET_INVENTORY_NEW_INVENTORY = `${WIS}/${GET_INVENTORY}/${NEW_INVENTORY}`;
export const GET_INVENTORY_FETCH_ERROR = `${WIS}/${GET_INVENTORY}/${FETCH_ERROR}`;
export const VEHICLE_TITLE_FETCH_ERROR = `${IDC}/${VEHICLE_TITLE}/${FETCH_ERROR}`;
export const VEHICLE_TITLE_NOT_FOUND = `${IDC}/${VEHICLE_TITLE}/${NOT_FOUND}`;
export const ADD_FETCH_ERROR = `ADD/${FETCH_ERROR}`;

export const STOCK_PHOTOS = 'images.dealer.com';
export const CUSTOM_PHOTOS = 'pictures.dealer.com';
