import React from 'react';
import { setClassNames } from 'ddc-classnames-js';
import { usePrefs } from 'wsm-common-data';
import PropTypes from 'prop-types';
import { getLoadingVehicleList } from '../utils';
import VehicleLoader from './Vehicle-Loader';
import Vehicle from './Vehicle';

const VehicleListWrapper = ({
	vehicleState,
	vehicleData,
	displayPref,
	trackEventElement,
	pageAlias
}) => {
	const {
		listItemClasses,
		listClasses: listClassesPref,
		displayStyle
	} = usePrefs();
	const { isLoading } = vehicleState;
	const { showPriceLabel, showVin } = displayPref;

	const isSingleRow = displayStyle === 'Standard Horizontal';
	const numberOfRows = isSingleRow ? 1 : 2;
	const maxVehiclesPerRow = isSingleRow ? 4 : 3;
	const displayLimit = maxVehiclesPerRow * numberOfRows;
	const leftJustifyVehicles = vehicleData.length < 3;

	const commonVehicleClasses = [
		listItemClasses !== 'BLANK' ? listItemClasses : '',
		'vehicle-card',
		'overflow-hidden',
		'position-relative',
		'my-1',
		'mb-4',
		'bg-contrast-low'
	];
	let vehicles;
	const gap = 2;
	const cardStyle = !isSingleRow
		? { flex: `0 0 ${Math.floor(100 / maxVehiclesPerRow) - gap}%` }
		: {};

	if (isLoading) {
		// Creates a list of skeletons
		vehicles = getLoadingVehicleList(displayLimit).map((vehicle) => {
			const { id } = vehicle;

			let prefClasses;

			if (showVin && showPriceLabel) {
				prefClasses = 'vehicle-card-skeleton-label-vin-pref';
			} else if (showVin) {
				prefClasses = 'vehicle-card-skeleton-vin-pref';
			} else if (showPriceLabel) {
				prefClasses = 'vehicle-card-skeleton-label-pref';
			} else {
				prefClasses = 'vehicle-card-skeleton-non-pref';
			}

			const skeletonClasses = [
				!isSingleRow ? 'vehicle-card-skeleton' : prefClasses,
				'mt-1 mb-3',
				...commonVehicleClasses
			];

			return (
				<li
					className={setClassNames(skeletonClasses)}
					style={cardStyle}
					key={`loader_${id}`}
					data-testid="card-li-loader"
				>
					<VehicleLoader
						vehicleState={vehicleState}
						displayStyle={displayStyle}
					/>
				</li>
			);
		});
	} else {
		// Creates a list vehicle cards
		vehicles = vehicleData.map((vehicle, index) => {
			const {
				bodyStyle,
				fuelEconomy,
				make,
				model,
				odometer,
				stockNumber,
				uuid,
				vin,
				year
			} = vehicle;

			const [cityFuelEconomy, highwayFuelEconomy] =
				fuelEconomy?.value?.split('/') || [];

			const trackingData = {
				bodyStyle,
				cityFuelEconomy,
				highwayFuelEconomy,
				make,
				model,
				odometer,
				stockNumber,
				uuid,
				vin,
				year
			};

			return (
				<li
					className={setClassNames(commonVehicleClasses)}
					key={uuid}
					style={cardStyle}
					data-testid="card-li"
				>
					<Vehicle
						info={vehicle}
						index={index}
						trackEventElement={trackEventElement}
						trackingData={trackingData}
						pageAlias={pageAlias}
					/>
				</li>
			);
		});
	}
	const listClasses = [
		listClassesPref !== 'BLANK' ? listClassesPref : '',
		!isSingleRow ? 'hide-from-7' : 'static-width overflow-hidden',
		leftJustifyVehicles
			? 'justify-content-left'
			: 'justify-content-between',
		'list-unstyled',
		'vehicle-list',
		'd-flex',
		'flex-wrap',
		'mb-0'
	];
	const cardContainer = { gap: `${gap}%` };

	return (
		<ul
			className={setClassNames(listClasses)}
			style={cardContainer}
			data-testid="static-width-carousel"
		>
			{vehicles}
		</ul>
	);
};

VehicleListWrapper.propTypes = {
	vehicleState: PropTypes.shape({
		isLoading: PropTypes.bool.isRequired,
		fadeIn: PropTypes.bool.isRequired
	}),
	vehicleData: PropTypes.arrayOf(
		PropTypes.shape({
			uuid: PropTypes.string.isRequired,
			imagePath: PropTypes.string.isRequired,
			link: PropTypes.string,
			totalPrice: PropTypes.oneOfType([
				PropTypes.string.isRequired,
				PropTypes.number.isRequired
			]),
			bodyStyle: PropTypes.string,
			cityFuelEconomy: PropTypes.string,
			highwayFuelEconomy: PropTypes.string,
			make: PropTypes.string,
			model: PropTypes.string,
			odometer: PropTypes.string,
			stockNumber: PropTypes.string,
			vin: PropTypes.string,
			year: PropTypes.number
		})
	).isRequired,
	displayPref: PropTypes.shape({
		showPriceLabel: PropTypes.bool.isRequired,
		showVin: PropTypes.bool.isRequired
	}),
	trackEventElement: PropTypes.string.isRequired,
	pageAlias: PropTypes.string.isRequired
};

export default VehicleListWrapper;
